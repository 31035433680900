import { Box, Grid } from '@mui/joy'
import Section from '../../Section/Section'
import VFeatureBlock from '../common/VFeatureBlock'
import { Card } from '../../Card'

function HowItWorks({ blocks }) {
    return (
        <Section bg="linear-gradient(0deg,#0d417d 0%,#0d417d 50%,#e3eff6 50%,#e3eff6 100%)">
            <Card p={4}>
                <Grid
                    container
                    spacing="15px"
                    columnSpacing={{
                        xs: 0,
                        sm: 5,
                        md: 15,
                    }}
                >
                    {blocks.map((flow, index) => (
                        <Grid
                            lg={4}
                            md={4}
                            sm={4}
                            xs={12}
                            // eslint-disable-next-line react/no-array-index-key
                            key={`Flow-Block-${index}`}
                        >
                            <Box height="100%">
                                <VFeatureBlock
                                    align="center"
                                    title={flow.title}
                                    description={flow.description}
                                    image={flow.image?.url}
                                />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Card>
        </Section>
    )
}

export default HowItWorks
